import { Container, Row, Col } from "react-bootstrap";
import Img from "react-cool-img";
import loadingImage from "../../images/CCE/team-member-placeholder.webp";
import errorImage from "../../images/error.webp";

const Area = ({ area }) => {
  return (
    <Container>
      <Row>
        <Col className="data-area">
          <div className="data-area-box">
            <h4>
              <i className="icofont icofont-eye-alt"></i> ¿Para qué?
            </h4>
            <p>{area.para}</p>
          </div>
        </Col>
        <Col className="data-area">
          <div className="data-area-box">
            <h4>
              <i className="icofont icofont-light-bulb"></i>¿Cómo?
            </h4>
            <p>{area.como}</p>
          </div>
        </Col>
        <Col className="data-area">
          <div className="data-area-box">
            <h4>
              <i className="icofont icofont-bullseye"></i>Objetivos
            </h4>
            <p>{area.objetivos}</p>
          </div>
        </Col>
      </Row>

      {/* SE COMENTAN LAS IMAGENES DE -NUESTRO EQUIPO- */}

      {/* <Row>
        <Col>
          {area.equipo.length === 0 ? null : (
            <div className="sub-title">
              <h3>NUESTRO EQUIPO</h3>
            </div>
          )}
        </Col>
      </Row> */}
      {/* <Row>
        <Col>
          <div className="container-team">
            {area.equipo.map((miembro) => (
              <a
                key={miembro.nombre}
                href={miembro.linkedin}
                target="_blank"
                rel="noreferrer"
                className="member-linkedin"
              >
                <div className="member">
                  <Img
                    placeholder={loadingImage}
                    src={miembro.foto}
                    error={errorImage}
                    alt="React Cool Img"
                  />
                  <div className="member-hover">
                    <p>{miembro.nombre}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Area;
