import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import sponsors from "../../utils/sponsors";
SwiperCore.use([Pagination, Autoplay]);

const SliderSponsors = () => {
  return (
    <Swiper
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 20,
        },
      }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      className="mySwiper"
    >
      {sponsors.map((empresa) => (
        <SwiperSlide key={empresa.id}>
          <div className="empresa-slider">
            <a href={empresa.url} target="_blank" rel="noreferrer">
              <img
                className="empresa-img"
                src={empresa.img}
                alt={empresa.nombre}
              ></img>
            </a>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderSponsors;
