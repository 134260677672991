import { presidentesHistoricos } from "../../utils/areasData";
const SliderPresidentes = () => {
  return (
    <div className="container-presidentes">
      {presidentesHistoricos.map((presidente) => (
        <div key={presidente.periodo} className="presidente">
          <img
            className="presidente-img"
            src={presidente.foto}
            alt={presidente.nombre}
          ></img>
          <div className="data-presidente">
            <p className="presidente-name">{presidente.nombre}</p>
            <p className="presidente-periodo">{presidente.periodo}</p>
          </div>
        </div>
      ))}
      <div className="faltante-presidente"></div>
    </div>
  );
};

export default SliderPresidentes;
