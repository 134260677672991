import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import Area from "../common/Area";
import SliderMembers from "../common/SliderMembers";
import {
  comisionDirectivaData,
  areasData,
  organoFiscalizacion,
  juntaElectoral,
  gerente,
  presidentesHistoricos,
} from "../../utils/areasData";
import SliderPresidentes from "../common/SliderPresidentes";
const CCE = () => {
  return (
    <>
      <div className="bg-top-cce">
        <Container>
          <Row>
            <Col>
              <div className="title-view">
                <h1>Cámara de Comercio Exterior de San Francisco y Región</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-text-top">
        <Container>
          <Row>
            <Col>
              <div className="text-top-cce">
                <p>
                  Desarrollar la cultura exportadora en la comunidad aumentando
                  el valor al socio de forma profesional, eficiente y
                  sustentable para hacer crecer las exportaciones. Trabajamos
                  para ser la entidad de máxima representatividad para el
                  desarrollo internacional de empresas en la región.{" "}
                </p>
              </div>
              <hr />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col>
            <div className="container-mision">
              <div className="mision">
                <p>
                  Definimos nuestra <span>MISIÓN</span> en desarrollar la
                  cultura exportadora en la comunidad aumentando el valor al
                  socio de forma profesional, eficiente y sustentable para hacer
                  crecer las exportaciones.
                </p>
              </div>

              <div className="mision">
                <p>
                  Trabajamos con la <span>VISIÓN</span> de ser la entidad de
                  máxima representatividad para el desarrollo internacional de
                  empresas en la región.
                </p>
              </div>

              <div className="mision">
                <p>
                  Nuestros <span>OBJETIVOS</span> son entregar un valor genuino
                  al socio para impulsar el crecimiento de las exportaciones y
                  potenciar la representatividad de la Cámara.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

        <Container className=" container-section">
        <Row>
          <Col>
            <div className="section-title">
              <h2>NUESTROS VALORES</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="valores-cce">
              <div className="valor-cce">
                <div>
                  <h5>Esfuerzo colaborativo</h5>
                  <p>
                    Predicamos la vocación de hacer crecer las exportaciones
                    independientemente del tamaño de empresa o sector.
                  </p>
                </div>
              </div>

              <div className="valor-cce">
                <div>
                  <h5>Resiliencia</h5>
                  <p>
                    Buscamos siempre la oportunidad de salir fortalecidos
                    independientemente del medio o circunstancias.
                  </p>
                </div>
              </div>

              <div className="valor-cce">
                <div>
                  <h5>Profesionalismo</h5>
                  <p>
                    Tenemos siempre la ambición de aplicar las mejores prácticas
                    y buscar la excelencia en lo que hacemos.
                  </p>
                </div>
              </div>

              <div className="valor-cce">
                <div>
                  <h5>Sustentabilidad</h5>
                  <p>
                    Trabajamos para el largo plazo con orgullo por la
                    trayectoria y con la responsabilidad por el futuro.
                  </p>
                </div>
              </div>

              <div className="valor-cce">
                <div>
                  <h5>Diversidad</h5>
                  <p>
                    Celebramos la construcción desde la más amplia diversidad
                    siempre potenciando a las personas y encontrando caminos
                    compartidos.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="cce-tabs container-section">
        <Row>
          <Col>
            <div className="section-title">
              <h2>Nuestras Áreas</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey={areasData[0].title}
              id="tabla2"
              className="mb-3"
            >
              <Tab eventKey={areasData[0].title} title={areasData[0].title}>
                <Area area={areasData[0]} />
              </Tab>

              <Tab eventKey={areasData[1].title} title={areasData[1].title}>
                <Area area={areasData[1]} />
              </Tab>

              <Tab eventKey={areasData[2].title} title={areasData[2].title}>
                <Area area={areasData[2]} />
              </Tab>

              <Tab eventKey={areasData[3].title} title={areasData[3].title}>
                <Area area={areasData[3]} />
              </Tab>

              <Tab eventKey={areasData[4].title} title={areasData[4].title}>
                <Area area={areasData[4]} />
              </Tab>

              <Tab eventKey={areasData[5].title} title={areasData[5].title}>
                <Area area={areasData[5]} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>

      <section className="services">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>Valor al socio</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-cubes"></i>
                <i className="icon back fa fa-cubes"></i>
                <p>
                  Generación del marco y espacio para el Networking entre
                  empresas socias.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-briefcase"></i>
                <i className="icon back fa fa-briefcase"></i>
                <p>
                  Personalización de programas del ProCórdoba en destinos,
                  calendarios y sectores.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa fa-money"></i>
                <i className="icon back fa fa fa-money"></i>
                <p>
                  Organización de misión inversa con ronda de negocios
                  internacionales en San Francisco.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-globe"></i>
                <i className="icon back fa fa-globe"></i>
                <p>
                  Vinculación con entes gubernamentales y no gubernamentales de
                  promoción a la producción, a los servicios, al comercio
                  exterior y a diversas cámaras.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-bar-chart"></i>
                <i className="icon back fa fa-bar-chart"></i>
                <p>
                  Realización de eventos con impacto para el desarrollo
                  internacional.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-line-chart"></i>
                <i className="icon back fa fa-line-chart"></i>
                <p>
                  Organización de capacitaciones para potenciar el desarrollo
                  internacional.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-users"></i>
                <i className="icon back fa fa-users"></i>
                <p>
                  Acompañamiento operativo a empresas para llevar adelante la
                  PRIMERA EXPO.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-certificate"></i>
                <i className="icon back fa fa-certificate"></i>
                <p>Gestión de certificado de origen en 24 horas.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service custom-institituion-values-item">
                <i className="icon front fa fa-bullhorn"></i>
                <i className="icon back fa fa-bullhorn"></i>
                <p>
                  Comunicación relevante de coyuntura y estratégica en diversos
                  temas fundamentales para el desarrollo internacional.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Container className=" container-section">
        <Row>
          <Col>
            <div className="section-title">
              <h2>COMISIÓN DIRECTIVA</h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="container-members">
            <h2>Integrantes</h2>
            <SliderMembers area={comisionDirectivaData}></SliderMembers>
          </Col>
        </Row>

        <Row>
          <Col className="container-members ">
            <div className="members-mini">
              <h2>Órgano de Fiscalización</h2>
              <SliderMembers area={organoFiscalizacion}></SliderMembers>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="container-members">
            <div className="members-mini">
              <h2>Junta Electoral</h2>
              <SliderMembers area={juntaElectoral}></SliderMembers>
            </div>
          </Col>
        </Row>

      </Container>

      <Container className=" container-section">
        <Row>
          <Col>
            <div className="section-title">
              <h2>PRESIDENTES HISTÓRICOS</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="flex-center">
            <SliderPresidentes presidentes={presidentesHistoricos} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CCE;
