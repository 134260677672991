import { Container, Row, Col } from "react-bootstrap";
import logoFooter from "../../images/logowhite.webp";
import logoISO from "../../images/BVCER_SansQR-ISO 9001/BVCER_SansQR-ISO 9001 B&W.png"

const Footer = () => {
  return (
    <>
      <footer>
        <Container>
          <Row>
            <Col>
              <div className="container-footer">
                <div className="logo-footer">
                  <div className="logo-friscop">
                    <img src={logoFooter} alt="Friscop logo" />
                  </div>
                </div>
                  <div style={{ margin: "20px 0px", width: "200px" }}>
                    <img src={logoISO} alt="Logo ISO" />
                  </div>
                

                <div className="social-footer">
                  <ul className="redes-footer">
                    <li>
                      <a
                        className="link-social"
                        href="https://www.facebook.com/CCESanFco"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="link-social"
                        href="https://www.linkedin.com/company/c%C3%A1mara-de-comercio-exterior-de-san-francisco-y-la-regi%C3%B3n/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="contact-footer">
                  <ul className="contact-list">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <a
                        href="tel:+549-3564-602238"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/*+549 3564-632808*/}
                        +549 3564-602238
                      </a>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <a
                        href="mailto:secretaria@ccesf.com.ar"
                        target="_blank"
                        rel="noreferrer"
                      >
                        secretaria@ccesf.com.ar
                      </a>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      <a
                        href="https://www.google.com/maps/dir//Santiago+Pampiglione+4891,+San+Francisco,+C%C3%B3rdoba/@-31.4388038,-62.1264021,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x95cb288a13120053:0x44dbe50ea85be34f!2m2!1d-62.1242134!2d-31.4388038!3e3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Santiago Pampiglione 4891
                      </a>
                    </li>
                    {/* <li>
                      <div style={{margin: "20px 0px"}}>
                        <img src={logoISO} alt="Logo ISO" />
                      </div>
                    </li> */}
                  </ul>
                </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="copy-footer">
          <p>
            &copy; All Right Reserved. Design By{" "}
            <a href="https://rooftop.dev/" target="_blank" rel="noreferrer">
              Rooftop
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
