import Ancham from "../images/home/entidades/amcham_logo.webp";
import Cacec from "../images/home/entidades/ccec_logo.webp";
import Aaici from "../images/home/entidades/aaici_logo.webp";
import Fecacera from "../images/home/entidades/fecacera_logo.webp";
import Cancilleria from "../images/home/entidades/mrec_logo.webp";
import UIndustrial from "../images/home/entidades/pisf_logo.webp";
import Pro from "../images/home/entidades/pro_cba_logo.webp";
import Aim from "../images/home/entidades/aim_logo.webp";
import Uic from "../images/home/entidades/uic_logo.webp";
import ArgExporta from "../images/home/entidades/arg_exp_logo.webp";
import Cac from "../images/home/entidades/cac_logo.webp";

const entidadesAsociadas = [
  { url: "https://www.amcham.com.ar/", img: Ancham },

  { url: "https://www.cacec.com.ar/", img: Cacec },

  { url: "https://www.inversionycomercio.org.ar/", img: Aaici },

  { url: "http://www.fecacera.com.ar/", img: Fecacera },

  {
    url: "https://www.cancilleria.gob.ar/es/argentinatradenet/",
    img: Cancilleria,
  },

  { url: "https://www.parqueindustrialsanfrancisco.com/", img: UIndustrial },

  { url: "https://www.procordoba.org/", img: Pro },

  { url: "https://www.aimsanfrancisco.org/", img: Aim },

  { url: "https://www.uic.org.ar/", img: Uic },

  { url: "https://www.argentina.gob.ar/argentinaexporta/", img: ArgExporta },
  { url: "https://cac.com.ar/", img: Cac },
];

export default entidadesAsociadas;
