import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useRef } from "react";
import Typed from "typed.js";
import { Link } from "react-router-dom";
import SliderSponsors from "../common/SliderSponsors";
import SliderEntidades from "../common/SliderEntidades";
import imgNoticias from "../../images/home/news.webp";

const Home = () => {
  const el = useRef(null);
  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  });
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Impulsar el crecimiento de las exportaciones",
        "Entregar un valor genuino al socio",
        "Potenciar la cultura exportadora",
      ],
      startDelay: 300,
      typeSpeed: 70,
      backSpeed: 40,
      backDelay: 200,
      loop: true,
    });
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <>
      <div className="bg-top">
        <Container>
          <Row>
            <Col>
              <div className="home-top-container">
                <h1 className="title-top">
                  Cámara de Comercio Exterior de San Francisco y la Región
                </h1>
                <div className="container-effect">
                  <span className="text-effect" ref={el}></span>
                </div>
                <p className="text-top">
                  La Cámara de Comercio Exterior de San Francisco y la Región
                  fue fundada en 1984, con el desafío común de potenciar
                  esfuerzos y compartir experiencias de los empresarios
                  exportadores de la ciudad y región. Nace con el objetivo de
                  promover la cultura exportadora, generando sinergia para hacer
                  crecer el Comercio Internacional.
                </p>

                <div className="buttons-top">
                  <Link className="link-button" to="/empresas">
                    <button className="button-top">EMPRESAS</button>
                  </Link>
                  <Link to="/cce">
                    <button className="button-top-cce">CCE</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="quote-home">
              <p>
                Queremos desarrollar la cultura exportadora en la comunidad,
                aumentando el valor al socio de forma profesional, eficiente y
                sustentable para hacer crecer las exportaciones
              </p>
            </div>
            <hr />
          </Col>
        </Row>
      </Container>

      {/* <div className="container-sponsors">
        <Container>
          <Row>
            <Col>
              <div className="section-title">
                <h2>Nuestros Sponsors</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bg-empresas-slider">
          <Container>
            <Row>
              <Col>
                <SliderSponsors />
              </Col>
            </Row>
          </Container>
        </div>
      </div> */}

      <div className="bg-notices">
        <Container className="container-section">
          <Row>
            <Col>
              <div className="section-title">
                <h2 id="noticias">Noticias</h2>
              </div>
            </Col>
          </Row>

          <Row className="notices">
            <Col className="notices-container">
              <div className="facebook-container">
                <div
                  className="fb-page"
                  data-href="https://www.facebook.com/CCESanFco/"
                  data-tabs="timeline"
                  data-width="500"
                  data-height=""
                  data-small-header="true"
                  data-adapt-container-width="true"
                  data-hide-cover="true"
                  data-show-facepile="false"
                >
                  <blockquote
                    cite="https://www.facebook.com/CCESanFco/"
                    className="fb-xfbml-parse-ignore"
                  >
                    <a href="https://www.facebook.com/CCESanFco/">
                      Cámara de Comercio Exterior
                    </a>
                  </blockquote>
                </div>
              </div>
            </Col>
            <Col className="container-img-notices">
              <div className="img-notices">
                <img
                  className="img-notice"
                  src={imgNoticias}
                  alt="noticias"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="services single section container-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <div className="bread-title">
                  <h2>NUESTRAS ÁREAS</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="single-service">
                <div className="service-content">
                  <div className="service-features">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-feature">
                          <i className="fas fa-university"></i>
                          <h4>Institucional</h4>
                          <p>Potenciar la cultura exportadora</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-feature">
                          <i className="fas fa-location-arrow"></i>
                          <h4>Región</h4>
                          <p>Compartir información con impacto</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-feature">
                          <i className="fas fa-search-location"></i>
                          <h4>Internacional</h4>
                          <p>Acompañar la exportación</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-feature">
                          <i className="fas fa-campground"></i>
                          <h4>Formación</h4>
                          <p>Formar para exportar</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-feature">
                          <i className="fas fa-sitemap"></i>
                          <h4>Administración</h4>
                          <p>Gestionar sustentablemente</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="single-feature">
                          <i className="fas fa-headset"></i>
                          <h4>Consejo</h4>
                          <p>Aconsejar para crecer las exportaciones</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Container className="container-section">
        <Row>
          <Col>
            <div className="section-title">
              <h2>ESTADÍSTICAS</h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="container-estadistica">
            <div className="estadistica">
              <p>69</p>
              <h4>SOCIOS</h4>
            </div>
          </Col>

          <Col className="container-estadistica">
            <div className="estadistica">
              <p>52</p>
              <h4>PAÍSES</h4>
            </div>
          </Col>

          <Col className="container-estadistica">
            <div className="estadistica">
              <p>4000</p>
              <h4>PUESTOS DE TRABAJO</h4>
            </div>
          </Col>
          <Col className="container-estadistica">
            <div className="estadistica">
              <p>9400M</p>
              <h4>$ ANUALES</h4>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-gray">
        <Container>
          <Row>
            <Col>
              <div className="section-title">
                <h2>ENTIDADES ASOCIADAS</h2>
                <p>
                  Generamos puentes estratégicos con instituciones locales,
                  provinciales y nacionales.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <SliderEntidades></SliderEntidades>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
