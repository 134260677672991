import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Autoplay]);

const SliderMembers = ({ area }) => {
  const sizeSlider = (area) => {
    if (area.length > 2) {
      return 3;
    } else if (area.length === 1) {
      return 1;
    } else {
      return 2;
    }
  };
  return (
    <Swiper
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: sizeSlider(area),
          spaceBetween: 20,
        },
      }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      className="mySwiper"
    >
      {area.map((member) => (
        <SwiperSlide key={member.nombre}>
          <div className="member-slider">

            <div className="data-member">
              <p className="member-status">{member.cargo}</p>
              <p className="member-name">{member.nombre}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderMembers;
