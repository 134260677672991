import { useParams } from "react-router";
import empresasData from "../../utils/empresasData";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Empresa = () => {
  const { id } = useParams();
  const empresa = empresasData.find((empresa) => empresa.id === Number(id));

  return (
    <>
      <div className="bg-top-empresa">
        <Container>
          <Row>
            <Col>
              <div className="title-empresa">
                <h4>{empresa.nombre}</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col>
            <div className="container-empresa">
              {empresa.descripcion === "" ? null : (
                <div className="about-empresa">
                  <h5> Sobre nosotros</h5>
                  <p>{empresa.descripcion}</p>
                  {empresa.productos === "" ? null : (
                    <p>
                      <span>Principales productos: </span>
                      {empresa.productos}
                    </p>
                  )}
                </div>
              )}
              <div className="info-empresa">
                <h5> Contactanos</h5>
                <p>
                  <span>Domicilio:</span> {empresa.domicilio}
                </p>
                <p>
                  <span>Localidad:</span> {empresa.localidad}
                </p>
                <p>
                  <span>Telefono:</span> {empresa.telefono}
                </p>
                {empresa.web === "" ? null : (
                  <a href={empresa.web} target="_blank" rel="noreferrer">
                    {empresa.web === ""}
                    <button>VISITAR WEB</button>
                  </a>
                )}
                <a href={`tel:${empresa.telefono}`}>
           
                  <button>
                    <i className="call fas fa-phone-alt"></i>
                    LLAMAR
                  </button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="flex-center">
              <Link to={`/empresas/`}>
                <button className="button-back">
                  <i className="fas fa-long-arrow-alt-left"></i> VOLVER A
                  EMPRESAS
                </button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Empresa;
