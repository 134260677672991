import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/logo.webp";
const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect sticky="top" bg="white" expand="sm">
        <div className="contenedor-nav">
          <div className="contenedor-logo">
            <Navbar.Brand as={Link} to="/">
              <img src={logo} className="logo-menu" alt="Logo Cacesf" />{" "}
            </Navbar.Brand>
          </div>
          <div className="contenedor-menu">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link className="menu-link" eventKey="1" as={Link} to="/">
                  HOME
                </Nav.Link>
                <Nav.Link
                  className="menu-link"
                  eventKey="2"
                  as={Link}
                  to="/cce"
                >
                  CCE
                </Nav.Link>
                <Nav.Link
                  className="menu-link"
                  eventKey="2"
                  as={Link}
                  to="/empresas"
                >
                  EMPRESAS
                </Nav.Link>
                <Nav.Link
                  className="menu-link"
                  eventKey="3"
                  as={HashLink}
                  to="/#noticias"
                >
                  PRENSA
                </Nav.Link>
                <Nav.Link
                  className="menu-link"
                  eventKey="4"
                  as={Link}
                  to="/contacto"
                >
                  CONTACTO
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
