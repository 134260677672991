import { Container, Row, Col } from "react-bootstrap";
import empresasData from "../../utils/empresasData";
import { useState } from "react";
import { Link } from "react-router-dom";
import { shuffle } from "underscore";

const Empresas = () => {
  const [rubro, setRubro] = useState(shuffle(empresasData));
  const [empresasFiltradas, setEmpresasFiltradas] = useState(rubro);
  const [pageNumber, setPage] = useState(1);

  const selectRubro = (e) => {
    const empresasRubro = empresasData.filter(
      (empresa) => empresa.rubro === e.target.value
    );
    if (e.target.value === "todos") {
      setRubro(empresasData);
      setEmpresasFiltradas(empresasData);
    } else {
      setRubro(empresasRubro);
      setEmpresasFiltradas(empresasRubro);
    }
  };

  const searchCompany = (e) => {
    const empresasFilt = rubro.filter((empresa) =>
      empresa.nombre.includes(e.target.value.toUpperCase())
    );
    setEmpresasFiltradas(empresasFilt);
    if (empresasFilt.length < 10) {
      setPage(1);
    }
  };

  const getPage = (page) => {
    const EmpresasPerPage = 10;
    const sliceTo = EmpresasPerPage * page;
    const sliceFrom = sliceTo - EmpresasPerPage;
    return empresasFiltradas.slice(sliceFrom, sliceTo);
  };

  const numberOfPages = (empresas) => {
    let pages = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const number = Math.ceil(empresas.length / 10);
    return pages.slice(0, number);
  };

  return (
    <>
      <div className="bg-top-empresas">
        <Container>
          <Row>
            <Col>
              <div className="title-view">
                <h1>Promovemos la internacionalización de las empresas </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="section-title">
              <h2>EMPRESAS ASOCIADAS</h2>
              <p>
                Generamos puentes estratégicos con instituciones locales,
                provinciales y nacionales.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="container-control">
              <div className="select-rubro">
                <select onChange={selectRubro}>
                  <option value="todos">Todos los rubros</option>
                  <option value="asesoramiento">Asesoramiento</option>
                  <option value="alimentos">Alimentos</option>
                  <option value="caucho-goma">Caucho / Goma</option>
                  <option value="construccion-decoracion">
                    Construcción / Decoración
                  </option>
                  <option value="electricidad-electronica">
                    Electricidad / Electrónica
                  </option>
                  <option value="electrodomesticos">Electrodomésticos</option>
                  <option value="envases-embotellado">
                    Envases / Embotellado
                  </option>
                  <option value="hidraulica">Hidráulica</option>
                  <option value="informacion-software">
                    Información / Software
                  </option>
                  <option value="lubricantes-aceites">
                    Lubricantes / Aceites
                  </option>
                  <option value="metalurgia-maquinaria">
                    Metalurgia / Maquinaria
                  </option>
                  <option value="servicios-financieros">
                    Servicios Financieros
                  </option>
                  <option value="transporte-logistica">
                    Transporte / Logística
                  </option>
                  <option value="ventilacion">Ventilación</option>
                </select>
              </div>
              <div className="search">
                <input
                  onInput={searchCompany}
                  type="text"
                  placeholder="Ingresa el nombre a buscar"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="container-empresas">
        {empresasFiltradas.length === 0 ? (
          <h5>
            <i className="fas fa-times-circle"></i> NO HAY RESULTADOS PARA TU
            BÚSQUEDA{" "}
          </h5>
        ) : (
          getPage(pageNumber).map((empresa) => (
            <div key={empresa.id} className="empresa">
              <h5>{empresa.nombre}</h5>
              <p>
                <span>Domicilio:</span> {empresa.domicilio}
              </p>
              <p>
                <span>Localidad:</span> {empresa.localidad}
              </p>
              <p>
                <span>Teléfono:</span> {empresa.telefono}
              </p>
              {empresa.web === "" ? null : (
                <a href={empresa.web} target="_blank" rel="noreferrer">
                  <button>WEB</button>
                </a>
              )}
              <Link to={`/empresas/${empresa.id}`}>
                <button>DETALLE</button>
              </Link>
            </div>
          ))
        )}
        <div className="paginator">
          {numberOfPages(empresasFiltradas).length === 1
            ? null
            : numberOfPages(empresasFiltradas).map((number) => (
                <button
                  key={number}
                  className={number === pageNumber ? "page-selected" : "page"}
                  onClick={() => {
                    setPage(number);
                  }}
                >
                  {number}
                </button>
              ))}
        </div>
      </div>
    </>
  );
};

export default Empresas;
