import Home from "./components/views/Home.jsx";
import Header from "./components/layout/Header";
import React from "react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from "./components/layout/Footer";
import CCE from "./components/views/CCE";
import Empresas from "./components/views/Empresas";
import Empresa from "./components/common/Empresa";
import Contacto from "./components/views/Contacto";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header></Header>
        <Switch>
          <Route path="/contacto">
            <Contacto />
          </Route>

          <Route path="/empresas/:id">
            <Empresa></Empresa>
          </Route>
          <Route path="/empresas">
            <Empresas></Empresas>
          </Route>
          <Route path="/cce">
            <CCE></CCE>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>

      <Footer></Footer>
    </>
  );
}

export default App;
