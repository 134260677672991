import FlorenciaRicca from "../images/CCE/florencia-ricca-3.webp";
import ManuelMontali from "../images/CCE/manuel-montali-2.webp";
import DavidBeccarini from "../images/CCE/david-beccarini-2.webp";
import GustavoDamiano from "../images/CCE/gustavo-damiano-2.webp";
import MartinaBlasi from "../images/CCE/martina-blasi-1.webp";
import NicolasCapello from "../images/CCE/nicolas-capello-2.webp";
import DiegoPeretti from "../images/CCE/diego-peretti-3.webp";
import MatiasJaluf from "../images/CCE/matias-jaluf-2.webp";
import MicaelaOlocco from "../images/CCE/micaela-olocco-1.webp";
import FernandoBertorello from "../images/CCE/fernando-bertorello-1.webp";
import VictoriaRicca from "../images/CCE/victoria-ricca-2.webp";
import GustavoVaralda from "../images/CCE/gustavo-varalda-1.webp";
import GabrielAsencio from "../images/CCE/gabriel-asencio-1.webp";
import RominaVairolatti from "../images/CCE/romina-vairolatti-1.webp";
import CarlosBarbero from "../images/CCE/carlos-barbero-3.webp";
import ClaudioDussin from "../images/CCE/claudio-dussin-3.webp";
import CeciliaAme from "../images/CCE/cecilia-ame.webp";
import GabrielCerutti from "../images/CCE/gabriel-cerutti-1.webp";
import MarcosZuazaga from "../images/CCE/marcos-zuazaga-2.webp";
import RubenFilippa from "../images/CCE/ruben-filippa-2.webp";
import VirginiaSalomon from "../images/CCE/virginia-salomon-1.webp";
import RobertoPodadera from "../images/CCE/roberto-podadera-1.webp";
import GuidoGaviglio from "../images/CCE/guido-gaviglio-1.webp";
import GustavoGandino from "../images/CCE/gustavo-gandino-1.webp";
import ErnestoBarbero from "../images/CCE/ernesto-barbero.webp";
import AntonioBarbero from "../images/CCE/antonio-barbero-hijo.webp";
import RobertoPodaderaPadre from "../images/CCE/roberto-podadera-padre.webp";
import CarlosDelich from "../images/CCE/carlos-delich.webp";
import NoFoto from "../images/CCE/team-member-placeholder.webp";
import EnriqueGaviglio from "../images/CCE/enrique-gaviglio.webp";
import BartolomeGandino from "../images/CCE/bartolome-gandino.webp";
import HeraldoRuffino from "../images/CCE/heraldo-ruffino.webp";
import DanielCapello from "../images/CCE/daniel-capello.webp";
import AlejandroRoasso from "../images/CCE/alejandro-roasso.webp";

export const areasData = [
  {
    title: "Institucional",
    para: "Potenciar la cultura exportadora, enfocada en agregar valor al socio y posicionar a la institución en la ciudad, provincia y país congruentemente con los valores de la institución como motor de desarrollo y oportunidades para la región.",
    como: "Mantener una agenda eficiente de vinculación y comunicación a las empresas asociadas, las instituciones y la comunidad que genere un marco de sinergia entre los socios.",
    objetivos:
      "Interrelacionar a los socios. Visibilizar a la cámara a nivel local y nacional. Fomentar la participación del asociado para la internacionalización.",
    equipo: [
      {
        nombre: "Florencia Ricca",
        foto: FlorenciaRicca,
        linkedin: "https://www.linkedin.com/in/maría-florencia-ricca-51aa18102",
      },
      {
        nombre: "Manuel Montali",
        foto: ManuelMontali,
        linkedin: "https://www.linkedin.com/in/manuel-montali-48318118a",
      },
      {
        nombre: "David Beccarini",
        foto: DavidBeccarini,
        linkedin: "https://www.linkedin.com/in/david-jose-beccarini-998209158",
      },
      {
        nombre: "Gustavo Damiano",
        foto: GustavoDamiano,
        linkedin: "https://www.linkedin.com/in/gustavo-damiano-03a568a5",
      },
      {
        nombre: "Martina Blasi",
        foto: MartinaBlasi,
        linkedin: "https://www.linkedin.com/in/martina-blasi-72230bb2",
      },
      {
        nombre: "Diego Peretti",
        foto: DiegoPeretti,
        linkedin: "https://www.linkedin.com/in/diego-ruben-peretti-62b005123",
      },
      {
        nombre: "Nicolás Capelo",
        foto: NicolasCapello,
        linkedin: "https://www.linkedin.com/in/nicolás-capello-98a37b5b",
      },
      {
        nombre: "Matias Jaluf",
        foto: MatiasJaluf,
        linkedin: "https://www.linkedin.com/in/mati-jaluf-9b4913b7",
      },
      {
        nombre: "Micaela Olocco",
        foto: MicaelaOlocco,
        linkedin:
          "https://www.linkedin.com/in/micaela-soledad-olocco-36b1a3156",
      },
    ],
  },

  {
    title: "Internacional",
    para: "Acompañar a las empresas asociadas en el proceso de exportación.",
    como: "Generar actividades e información relevante para la actividad internacional.",
    objetivos:
      "Generar nuevas empresas exportadoras y consolidar las existentes.",
    equipo: [
      {
        nombre: "Fernando Bertorello",
        foto: FernandoBertorello,
        linkedin: "https://www.linkedin.com/in/fernando-bertorello-53196321",
      },
      {
        nombre: "Victoria Ricca",
        foto: VictoriaRicca,
        linkedin: "https://www.linkedin.com/in/maria-victoria-ricca-0b430bb",
      },
      {
        nombre: "Gustavo Varalda",
        foto: GustavoVaralda,
        linkedin: "https://www.linkedin.com/in/gustavo-varalda-69b2b51b4",
      },
      {
        nombre: "Gabriel Asencio",
        foto: GabrielAsencio,
        linkedin: "https://www.linkedin.com/in/gabriel-asencio-b72b18a9",
      },
      {
        nombre: "Romina Vairolatti",
        foto: RominaVairolatti,
        linkedin: "#",
      },
      {
        nombre: "Nicolás Capello",
        foto: NicolasCapello,
        linkedin: "https://www.linkedin.com/in/nicolás-capello-98a37b5b",
      },
      {
        nombre: "Diego Peretti",
        foto: DiegoPeretti,
        linkedin: "https://www.linkedin.com/in/diego-ruben-peretti-62b005123",
      },
      {
        nombre: "Matias Jaluf",
        foto: MatiasJaluf,
        linkedin: "https://www.linkedin.com/in/mati-jaluf-9b4913b7",
      },
      {
        nombre: "Micaela Olocco",
        foto: MicaelaOlocco,
        linkedin:
          "https://www.linkedin.com/in/micaela-soledad-olocco-36b1a3156",
      },
    ],
  },
  {
    title: "Región",
    para: "Compartir información con impacto que aumente el valor al socio, logrando la institución una evolución en posicionamiento y proyección.",
    como: "Ejecutar una estructura de encuestas trimestrales que permita elaborar y difundir informes.",
    objetivos:
      "Generar información relevante para la representatividad de la Cámara y toma de decisión de las empresas asociadas.",
    equipo: [
      {
        nombre: "Carlos Barbero",
        foto: CarlosBarbero,
        linkedin: "#",
      },

      {
        nombre: "Cecilia Amé",
        foto: CeciliaAme,
        linkedin: "https://www.linkedin.com/in/cecilia-amé-b01005ba",
      },
      {
        nombre: "Claudio Dussin",
        foto: ClaudioDussin,
        linkedin: "https://www.linkedin.com/in/claudio-daniel-dussin-926282a1",
      },
    ],
  },

  {
    title: "Formación",
    para: "Formar la cultura exportadora a través de instituciones educativas, generando actividades de valor para la comunidad y empresas asociadas.",
    como: "Conectar los formadores de entidades educativas regionales con la institución. Relevar las principales necesidades de capacitación de empresas asociadas.",
    objetivos: "Fortalecer el rol del exportador en la comunidad.",
    equipo: [
      {
        nombre: "Ruben Filippa",
        foto: RubenFilippa,
        linkedin: "https://www.linkedin.com/in/rubén-filippa-87ba10a",
      },
      {
        nombre: "Marcos Zuazaga",
        foto: MarcosZuazaga,
        linkedin: "https://www.linkedin.com/in/marcos-zuazaga-88baab39/",
      },
      {
        nombre: "Virginia Salomón",
        foto: VirginiaSalomon,
        linkedin: "https://www.linkedin.com/in/m-virginia-salomón-25468947",
      },
      {
        nombre: "Gabriel Cerutti",
        foto: GabrielCerutti,
        linkedin: "https://www.linkedin.com/in/gabriel-cerutti-b9129a9/",
      },
    ],
  },
  {
    title: "Administración",
    para: "Gestionar sustentablemente nuestra institución de forma profesional y eficiente con buenas prácticas contables, impositivas y jurídicas.",
    como: "Desarrollar actividades autosuficientes y gestionar la evolución de la institución con un Balanced Scored Card de gestión estratégica.",
    objetivos:
      "Procurar un financiamiento genuino con superávit constante en cada actividad y desarrollar un Balanced Score Card.",
    equipo: [
      {
        nombre: "Roberto Podadera",
        foto: RobertoPodadera,
        linkedin: "https://www.linkedin.com/in/roberto-podadera-5b804420",
      },
    ],
  },

  {
    title: "Consejo",
    para: "Aconsejar al equipo de gestión y fortalecer planes estratégicos.",
    como: "Transmitir valores, mantener el propósito y trasladar las experiencias desde la fundación de la institución.",
    objetivos: "Potenciar la esencia de la institución.",
    equipo: [],
  },
];

export const comisionDirectivaData = [
  { nombre: "Rubén Filippa", cargo: "Presidente" },
  { nombre: "Florencia Ricca", cargo: "Vicepresidente"},
  { nombre: "Andrea Pessot", cargo: "Secretario"},
  { nombre: "Carlos Barbero", cargo: "Pro Secretario"},
  { nombre: "Fernando Bertorello", cargo: "Tesorero"},
  { nombre: "Cecilia Ame", cargo: "Pro Tesorera"},
  { nombre: "Gabriela Gaviglio", cargo: "1° Vocal"},
  { nombre: "Agustin Benso", cargo: "2° Vocal"},
  { nombre: "Guido Gaviglio", cargo: "Vocal Suplente"},
];

export const organoFiscalizacion = [
  {
    nombre: "Heraldo Rufino",
    cargo: "Revisor de cuentas Titular",
  },
  {
    nombre: "David Beccarini",
    cargo: "Revisor de cuentas Suplente",
  },
];

export const juntaElectoral = [
  { nombre: "Diego Peretti", cargo: "Titular"},
  { nombre: "Betiana Sufia", cargo: "Titular"},
  { nombre: "Guillermo Quiroga", cargo: "Suplente"},
];


export const presidentesHistoricos = [
  {
    nombre: "Ernesto Barbero",
    periodo: "13/07/1984 - 31/07/1985",
    foto: ErnestoBarbero,
  },
  {
    nombre: "Enrique Gaviglio",
    periodo: "31/07/1985 - 18/08/1986",
    foto: EnriqueGaviglio,
  },
  { nombre: "Dante Mosca", periodo: "18/08/1986 - 28/11/1986", foto: NoFoto },

  {
    nombre: "Antonio Barbero",
    periodo: "28/11/1986 - 31/05/1990",
    foto: AntonioBarbero,
  },
  {
    nombre: "Roberto Podadera",
    periodo: "31/05/1990 - 28/06/1993",
    foto: RobertoPodaderaPadre,
  },
  {
    nombre: "Antonio Barbero",
    periodo: "28/06/1993 - 27/09/1994",
    foto: AntonioBarbero,
  },

  {
    nombre: "Enrique Gaviglio",
    periodo: "27/09/1994 - 17/07/1995",
    foto: EnriqueGaviglio,
  },
  {
    nombre: "Bartolome Gandino",
    periodo: "17/07/1995 - 19/05/1997",
    foto: BartolomeGandino,
  },
  {
    nombre: "Heraldo Ruffino",
    periodo: "19/05/1997 - 01/08/1998",
    foto: HeraldoRuffino,
  },
  {
    nombre: "Juan José Bonzano",
    periodo: "01/08/1998 - 27/09/1999",
    foto: NoFoto,
  },
  {
    nombre: "Daniel Capello",
    periodo: "27/09/1999 - 01/10/2000",
    foto: DanielCapello,
  },

  {
    nombre: "Gustavo Gandino",
    periodo: "01/10/2000 - 09/10/2001",
    foto: GustavoGandino,
  },
  {
    nombre: "Alejandro Roasso",
    periodo: "09/10/2001 - 15/10/2002",
    foto: AlejandroRoasso,
  },
  {
    nombre: "Antonio Barbero",
    periodo: "15/10/2002 - 01/11/2003",
    foto: AntonioBarbero,
  },

  {
    nombre: "Heraldo Ruffino",
    periodo: "01/11/2003 - 01/11/2004",
    foto: HeraldoRuffino,
  },
  {
    nombre: "Enrique Gaviglio",
    periodo: "01/11/2004 - 01/02/2006",
    foto: EnriqueGaviglio,
  },

  {
    nombre: "Carlos Delich",
    periodo: "01/02/2006 - 01/02/2007",
    foto: CarlosDelich,
  },

  {
    nombre: "Carlos Barbero",
    periodo: "01/02/2007 - 31/08/2009",
    foto: CarlosBarbero,
  },
  {
    nombre: "Gustavo Varalda",
    periodo: "01/09/2009 - 01/10/2011",
    foto: GustavoVaralda,
  },
  {
    nombre: "Rubén Filippa",
    periodo: "01/10/2011 - 01/10/2013",
    foto: RubenFilippa,
  },
  {
    nombre: "Claudio Dussin",
    periodo: "01/10/2013 - 31/08/2017",
    foto: ClaudioDussin,
  },
  {
    nombre: "Gustavo Gandino",
    periodo: "01/09/2017 - 31/08/2019",
    foto: GustavoGandino,
  },
  {
    nombre: "Guido Gaviglio",
    periodo: "01/09/2019 - 25/11/2021",
    foto: GuidoGaviglio,
  },
  {
    nombre: "Florencia Ricca",
    periodo: "25/11/2021 - 21/12/23",
    foto: FlorenciaRicca,
  },
  {
    nombre: "Rubén Filippa",
    periodo: "22/12/23 - Presente",
    foto: RubenFilippa,
  }

];
