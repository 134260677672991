const empresas = [
  {
    id: 1,
    nombre: "AIMAR S.R.L",
    domicilio: "Colón 1150",
    localidad: " San Francisco (Córdoba)",
    telefono: "+54 3564-423407",
    web: "https://www.aimarfrenos.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Aimar Repuestos es una empresa que tiene como principal objetivo convertirse en una empresa líder en la comercialización de repuestos de frenos a nivel nacional, buscando cubrir todas las demandas en materia de frenos de automóviles, camionetas, utilitarios, camiones, máquinas agrícolas y viales. Entrega inmediata, stock permanente, atención personalizada, personal calificado y precios competitivo son los pilares que la distinguen de otras empresas del país.",
    productos:
      "Antivibradores, arandelas de aluminio, cables sensores de freno, campanas de freno, cilindro auxiliar de embrague, equipos de frenos, flexibles.",
  },

  {
    id: 2,
    nombre: "ESTUDIO ADUANERO ARBONES",
    domicilio: "Av. Cabildo Nº 2230 Piso 9 Of. I",
    localidad: "Capital Federal (Buenos Aires)",
    telefono: " +54 011-52170197",
    web: "",
    rubro: "asesoramiento",
    descripcion:
      "El Estudio Aduanero Arbones es una empresa radicada en la ciudad de Buenos Aires y que funciona como corredor en Montevideo y San Francisco. Es una empresa que funciona como despachante de aduana y brinda servicios de consultoría en comercio exterior.",
    productos: "",
  },

  {
    id: 3,
    nombre: "ESTUDIO ADUANERO BERTORELLO Y ASOCIADOS",
    domicilio: "Iturraspe 1471",
    localidad: "San Francisco (Córdoba)",
    telefono: " +54 3564-479347",
    web: "https://www.bertorelloyasoc.com.ar/",
    rubro: "asesoramiento",
    descripcion:
      "Vinculada al comercio exterior y bajo el lema “Estamos para bridarles soluciones”, el Estudio Aduanero Bertorello y Asociados es una empresa que responde ante los constantes cambios del mercado, contando con personal capacitado en el manejo de todas las herramientas de comercio internacional. Su objetivo es brindarles a sus clientes un asesoramiento personalizado, optimizando costos y cumpliendo con su trabajo de forma eficaz y responsable.",
    productos: "",
  },
  {
    id: 4,
    nombre: "FRANCISCO CONSTABLE S.R.L",
    domicilio: "Iturraspe 1909 - 1er. piso of. 19",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-432256",
    web: "http://www.constable.com.ar/",
    rubro: "asesoramiento",
    descripcion:
      "Francisco Constable es una empresa de servicios en Comercio Exterior y Despachantes de Aduana, con más de 35 años de trayectoria y experiencia en el mercado, los cuales avalan nuestro prestigio en Logística y Comercio Internacional.Cuenta con personal altamente capacitado para dar respuestas rápidas y efectivas a los requerimientos de nuestros clientes, lo que permite realizar operaciones con aduanas de Argentina y reparticiones intervinientes en el Comercio Exterior. ",
    productos: "",
  },
  {
    id: 5,
    nombre: "EMEVG S.A - EMEGOM",
    domicilio: "Lothar Badersbach 4352",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-445832",
    web: "https://emegom.com/",
    rubro: "caucho-goma",
    descripcion:
      "Denominándose como una compañía dedicada a la fabricación de piezas de goma, goma metal y goma tela para productos industriales, Emegom  brinda a sus clientes piezas confiables, entrega puntual y precios bajos. Especializados en la producción de  bujes, silent block, soportes, antivibradores y fuelles,  abastece a grandes industrias de Argentina, Brasil, Estados Unidos, México y Alemania desde 1995.",
    productos: "Bujes, silent block, soportes, antivibradores y fuelles",
  },

  {
    id: 6,
    nombre: "EVERWEAR S.A",
    domicilio: "Av. Rosario de Santa Fe 1698",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-424240",
    web: "https://www.everwear.com.ar/",
    rubro: "caucho-goma",
    descripcion:
      "Desde 1976, Ever Wear se ha convertido en una de las distribuidoras e importadoras de productos de caucho más prestigiosas del país, logrando consolidar una carpeta comercial altamente competitiva, ofreciendo un catálogo compuesto por correas, mangueras, cintas transportadoras, engranajes, abrazaderas, cadenas, grampas y polietileno , alcanzando el liderazgo del mercado argentino por el crecimiento sostenido a lo largo de más de una década.",
    productos:
      "Máquinas de prensado de mangueras hidráulicas, terminales hidráulicos/ mangueras alta, media y baja presión, poleas de fundición",
  },
  {
    id: 7,
    nombre: "SOLANO ESTUDIO ADUANERO",
    domicilio: "Pueyrredon 65",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-520858",
    web: "https://www.estudiosolano.com",
    rubro: "asesoramiento",
    descripcion:
      "Solano Estudio Aduanero es una empresa dedicada al comercio internacional especializada en despachos de aduana, agente de transporte, trámites especiales, logística internacional y consultoría en comercio exterior.",
    productos: "",
  },
  {
    id: 8,
    nombre: "CENTRO S.A",
    domicilio: "Av. 9 de Septiembre 1171",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-422741",
    web: "https://www.karikal.com.ar/",
    rubro: "construccion-decoracion",
    descripcion:
      "Con más de 60 años en el mercado, Karikal es una empresa encargada de proveer insumos y soluciones sustentables para la industria del mueble y la producción en seco. Además, cuenta con certificados acorde con normativas internacionales y efectúa pertinentes inversiones para incorporar tecnología de punta, llegando a ser competitivos en mercados internacionales.",
    productos: "Laminados decorativos, pisos flotantes, revestimientos",
  },

  {
    id: 9,
    nombre: "FRONTERA LIVING S.A.",
    domicilio: "Marcos Nicolini 1435",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-431130",
    web: "https://www.fronteraliving.com/",
    rubro: "construccion-decoracion",
    descripcion:
      "Con más de 30 años de experiencia en el mercado, Frontera Living tiene la misión de crear bienestar y espacios confortables a través de una eficaz organización. A través de la fabricación de productos con calidad y combinando el confort con diseños de vanguardia, priorizan las necesidades y expectativas de sus clientes.",
    productos: "Sillones, colchones, respaldos, almohadones.",
  },

  {
    id: 10,
    nombre: "COMPUTROL S.R.L",
    domicilio: "Santiago Pampiglione 4630",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-445800",
    web: "http://computrol-ing.com.ar/",
    rubro: "electricidad-electronica",
    descripcion:
      "Computrol es una empresa de nuestra ciudad que cuenta con un claro propósito: diseñar, producir y comercializar equipos eléctricos/electrónicos para aplicación en industrias, maquinaria agrícola y afines, seguros e innovadores, basados en una Ingeniería con alta experiencia y en la calidad de su producción y prestación de servicio postventa. Gracias al trabajo profesional que realiza, le permite mejorar los procesos de sus clientes, aportando innovación, creatividad y tecnología acorde a sus necesidades.",
    productos:
      "Estabilizadores y UPS, Protectores de tensión,fuentes y convertidores, tableros de comando y control, columnas de dirección.",
  },

  {
    id: 11,
    nombre: "C.A.P.Y.C. LTDA",
    domicilio: "Bv. San Martín 231",
    localidad: "Devoto (Córdoba)",
    telefono: "+54 3564-482226",
    web: "http://www.capyc.com.ar/",
    rubro: "envases-embotellado",
    descripcion:
      "CAPYC es una cooperativa dedicada a diferentes actividades, entre las cuales se encuentra la fabricación de envases plásticos, dulce de leche y conjuntos para instituciones, entre otros productos. Radicada en la localidad de Devoto y con más de 200 empleados, esta empresa cuenta con clientes a lo largo del país y en el exterior.",
    productos: "Envases plásticos, dulce de leche, tapas y sobretapas",
  },
  {
    id: 12,
    nombre: "HECTOR CODINI & CÍA SRL",
    domicilio: "Av. Urquiza 55",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-425200",
    web: "http://www.codini.com.ar/",
    rubro: "electrodomesticos",
    descripcion:
      "Hector Codini S.A se transformó en sinónimo de diseño y calidad, desarrollando una estrategia global de crecimiento permanente basada en detectar y satisfacer las necesidades del cliente. Con una planta de última tecnología de 20.000 m2, Codini diseña, produce y comercializa electrodomésticos bajo un intenso Sistema de Gestión de Calidad.",
    productos: "Lavarropa, secarropa y ventiladores.",
  },

  {
    id: 13,
    nombre: "ENCAPA S.A.",
    domicilio: "Calle 112 Nº 2438",
    localidad: "Frontera (Santa Fe)",
    telefono: "+54 3564 425800",
    web: "http://encapa.com/",
    rubro: "envases-embotellado",
    descripcion:
      "Encapa es uno de los principales productores de packaging y POP del mercado. Diseñamos, fabricamos e implantamos nuestros productos. Compuesta por un capital humano creativo y con actitud positiva, Encapa se adapta a los recurrentes cambios y comprometiendose con el cuidado del medioambiente, ya que sus productos son realizados con cartón corrugado, el cual se elabora con papel reciclado. Cuenta con Normas de caliad, como la ISO 9001.",
    productos:
      "POP: ambientaciones, exhibidores de pie y de mostrador, islas, decoración. Packaging: industrial, navidad, hogar. ",
  },

  {
    id: 14,
    nombre: "FRUSSO S.A.",
    domicilio: "Av. Rosario de Santa Fe 961",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-425270",
    web: "https://www.frusso.com/",
    rubro: "envases-embotellado",
    descripcion:
      "Frusso SA es una empresa que se dedica a la fabricación de máquinas para la elaboración de cervezas, tanques de acero inoxidable y máquinas de embotellado. Cuentan con dos plantas de producción que totalizan 4.000 metros cuadrados cubiertos, además de operar con personal técnico y operativo capacitado para afrontar nuevos desafíos. Trabajan con normas de calidad de Argentina y de otros países.",
    productos:
      "Máquinas para la elaboración de cervezas, tanques de acero inoxidable y máquinas de embotellado",
  },

  {
    id: 15,
    nombre: "HIDRAR S.A.",
    domicilio: "Santiago Pampiglione 4535",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-445917",
    web: "http://www.hbtorletti.com.ar/",
    rubro: "hidraulica",
    descripcion:
      "El asesoramiento en la elección del producto adecuado, la variedad y versatilidad de los productos ofrecidos, junto con la seriedad y responsabilidad en el trabajo diario consolidaron a Hidrar S.R.L como una empresa referente para la provisión de herramientas hidráulicas. Con un equipo de trabajo renovado, fabrica, importa y exporta herramientas hidráulicas y neumáticas con la impronta de ser cada día mejores.",
    productos:
      "Prensas hidráulicas, herramientas para gomerías, criques hidráulicos, bombas hidráulicas",
  },

  {
    id: 17,
    nombre: "SMARTFENSE",
    domicilio: "Salta 182",
    localidad: "Capital Federal (Buenos Aires)",
    telefono: "0800-3451755",
    web: "https://www.smartfense.com/",
    rubro: "informacion-software",
    descripcion:
      "Smartfense es una plataforma de capacitación y concienciación en seguridad de la información que genera hábitos seguros en los usuarios finales. Para lograrlo integra herramientas de evaluación, de educación y refuerzo, así como de calendarización, medición y herramientas de auditoría.",
    productos: "",
  },

  {
    id: 18,
    nombre: "F Y G SOFTWARE",
    domicilio: "Jonas Salk 342",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-434999",
    web: "http://www.fgkpsoft.com.ar/",
    rubro: "informacion-software",
    descripcion:
      "F&G es una empresa de software de San Francisco que ofrece una amplia variedad de productos para diferentes necesidades, desarrollando programas para costos directos, liquidación de sueldos, administración de clubes, liquidación de IVA e Ingresos Brutos, programas para alojar historiales clínicos, entre otros, adaptándose a las demandas de cada uno de sus clientes y apuntando a nichos de mercados.",
    productos: "",
  },

  {
    id: 19,
    nombre: "FENOGLIO Y CAPELLO S.R.L.",
    domicilio: "Ingeniero Huergo 1378",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-420882",
    web: "https://www.vulcanolubricacion.com.ar/",
    rubro: "lubricantes-aceites",
    descripcion:
      "Fenoglio y Capello S.R.L es una reconocida empresa dedicada a la fabricación y comercialización de productos para la gestión y transferencia de fluidos tales como: aceite, grasa, diésel, gasoil y AdBlue bajo la marca VULCANO.",
    productos:
      "Equipos para grasa, equipos para aceite, enrolladores de manguera, infladores de neumáticos, aspiradores y recolectores, pulverizadores, monitoreo y gestión de fluídos. ",
  },
  {
    id: 20,
    nombre: "BARBERO S.A.",
    domicilio: "Av. 9 de Septiembre 3795",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-439000",
    web: "https://www.barbero.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Es una PyME familiar que nació hace más de 65 años en San Francisco, y se dedica a la fabricación y comercialización de productos de bazar y ferretería, buscando siempre altos estándares de calidad y a precios acordes al mercado. En los últimos años se incorporaron productos como: amoladoras, morsas especiales, prensas de diversos tipos, herramientas manuales, exprimidores de cítricos y utensilios de cocina, entre otros.",
    productos:
      "Exprimidores de citrus, utensilios de cocina de hierro fundido, taladradoras, amoladoras, tornillos de banco y morsas especiales, cortacables, cortapernos, herramientas de mano ",
  },
  //
  // {
  //   id: 21,
  //   nombre: "BOETTO S.H",
  //   domicilio: "Libertad 540",
  //   localidad: "San Francisco (Córdoba)",
  //   telefono: "+54 3564-443356",
  //   web: "https://www.boettorepuestos.com",
  //   rubro: "metalurgia-maquinaria",
  //   descripcion:
  //     "Boetto S.H es una empresa que cuenta con una trayectoria de más de 30 años en la fabricación y comercialización de repuestos para tractores de marcas reconocidas como: Massey Ferguson, Jhon Deere, Deutz, Fiat y cosechadoras New Holland. Su planta industrial se encuentra estratégicamente ubicada en el centro del país, puntualmente en la ciudad de San Francisco, Córdoba, permitiéndonos que los envíos lleguen rápidamente a todo el territorio nacional sin altos costos de logística.",
  //   productos: "",
  // },

  {
    id: 22,
    nombre: "CESCA S.A.",
    domicilio: "Santiago Pampiglione 4322",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-427722",
    web: "http://www.cesca-sa.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Cesca S.A. es una empresa dedicada a la fabricación de extremos de dirección y rótulas para auto elevadores, camiones, tractores y cosechadoras. La empresa ocupa 2200 m2 cubiertos, dentro del Parque Industrial de la ciudad de San Francisco.",
    productos:
      "Extremos y barras de dirección para camiones, autoelevadores, tractores y cosechadoras. ",
  },

  {
    id: 23,
    nombre: "FERRETERIA GENERAL PAZ S.R.L.",
    domicilio: "25 de Mayo 55",
    localidad: "Freyre (Córdoba)",
    telefono: "+54 3564-461700",
    web: "https://fgp.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Cumpliendo con los compromisos entablados con sus clientes, la Ferretera General Paz trabaja día a día de manera clara y transparente, con el fin de lograr una imagen seria y confiable, cubriendo las necesidades de corralones, ferreterías industriales e hipermercados ubicados en distintos puntos del país. Cuenta con un capital humano altamente idóneo para alcanzar sus resultados con excelencia.",
    productos: "Artículos de ferretería  y corralón",
  },

  {
    id: 24,
    nombre: "HP REPUESTOS",
    domicilio: "Perú 1277",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-427681",
    web: "http://www.hprepuestos.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "HP repuestos se dedica a la fabricación de repuestos y accesorios para motocicletas, para proveer por medio de nuestros distribuidores al mercado motopartista de reposición. Basándose en los conceptos de Calidad y responsabilidad y mejora continua, la empresa capta nuevos clientes que confían en el producto y de esta manera amplia su red de comercialización, logrando presencia en todo el territorio argentino. Cuenta con certificación de normas ISO 9001:2008 lo que posiciona a la empresa como una líderes en el mercado de diseño y fabricación de accesorios para motocicletas.",
    productos: "Portaequipaje, manubrio, posapie",
  },

  {
    id: 25,
    nombre: "BANCO CREDICOOP C.L",
    domicilio: "Bv. 25 de Mayo 1763",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-424393",
    web: "https://www.bancocredicoop.coop/personas",
    rubro: "servicios-financieros",
    descripcion: "",
    productos: "",
  },

  {
    id: 26,
    nombre: "BANCO DE LA NACIÓN ARGENTINA S.A",
    domicilio: "Bv. 25 de Mayo 1702",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-421402",
    web: "https://www.bna.com.ar/Personas",
    rubro: "servicios-financieros",
    descripcion: "",
    productos: "",
  },

  {
    id: 27,
    nombre: "BANCO DE LA PROVINCIA DE CÓRDOBA S.A.",
    domicilio: "Carlos Pellegrini 40",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-438700",
    web: "https://www.bancor.com.ar/",
    rubro: "servicios-financieros",
    descripcion: "",
    productos: "",
  },

  {
    id: 28,
    nombre: "BANCO GALICIA S.A.",
    domicilio: "Bv. 25 de Mayo 1602",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 351-156471224",
    web: "https://www.bancogalicia.com/",
    rubro: "servicios-financieros",
    descripcion: "",
    productos: "",
  },
  {
    id: 30,
    nombre: "BANCO I.C.B.C. ARGENTINA S.A",
    domicilio: "Bv. 25 de Mayo 1740",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-479800",
    web: "https://www.icbc.com.ar/",
    rubro: "servicios-financieros",
    descripcion: "",
    productos: "",
  },

  {
    id: 31,
    nombre: "BANCO MACRO S.A.",
    domicilio: "Bv. 25 de Mayo 1587",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-420011",
    web: "https://www.macro.com.ar/",
    rubro: "servicios-financieros",
    descripcion: "",
    productos: "",
  },

  {
    id: 32,
    nombre: "BBB EXPRESS",
    domicilio: "J. Venier 1515",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-313209",
    web: "http://www.bbbexpress.com.ar/",
    rubro: "asesoramiento",
    descripcion: "BBB Express es una empresa de transporte que cuenta con sucursales en Córdoba Capital, San Francisco y Buenos Aires. Mediante sus servicios, se distribuyen y almacenan cargas de diferentes industrias.",
    productos: "",
  },

  {
    id: 33,
    nombre: "CAROSSIO , VAIROLATTI Y CIA S.R.L",
    domicilio: "San Lorenzo 31",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-420169",
    web: "http://carossio-vairolatti.com.ar/",
    rubro: "transporte-logistica",
    descripcion:
      "Carossio, Vairolatti & Cia es una empresa de transporte y logística con más de 60 años en funcionamiento. Cuenta con una importante flota de vehículos, lo que permite que los servicios que ofrecen sean integrales y accesible para las empresas que están vinculadas con el proceso de internacionalización o que desean exportar.",
    productos: "",
  },

  {
    id: 34,
    nombre: "GATTI S.A.",
    domicilio: "Av. Rosario de Santa Fe 298",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-420619",
    web: "https://www.gattisa.com.ar",
    rubro: "ventilacion",
    descripcion:
      "Con más de 50 años en el mercado y tras posicionarse como la primera en ventas nacionales, Gatti S.A es una empresa que se dedica a la comercialización de equipos de ventilación para el hogar, el comercio y la industria. Los altos niveles de desempeño, su performance y excelente relación calidad/precio también le permitió posicionarse en el comercio exterior. Contando con 20 líneas de productos y más de 2500 artículos diferentes, satisfacen la demanda de sus clientes a la perfección.",
    productos: "Equipos de ventilación domestica y ventilación industria",
  },

  {
    id: 35,
    nombre: "RDM LOGÍSTICA",
    domicilio: "Las Acacias 1471",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-669856",
    web: "https://www.rdmlogistica.com.ar",
    rubro: "asesoramiento",
    descripcion:
      "RDM logística tiene una misión sencilla: proporcionar servicios de alta calidad en tiempo y forma. Mediante el compromiso y la disponibilidad, esta empresa ofrece una plataforma  donde cada cliente puede acceder a un seguimiento online de sus cargas las 24 hs los 365 días del año.",
    productos: "",
  },
  //
  // {
  //   id: 36,
  //   nombre: "SAN CRISTOBAL SEGUROS",
  //   domicilio: "Bv. 9 de Julio 1599",
  //   localidad: "San Francisco (Córdoba)",
  //   telefono: "+54 3564-332995",
  //   web: "http://www.sancristobal.com.ar",
  //   rubro: "asesoramiento",
  //   descripcion:
  //     "San Cristobal Seguros es una empresa que nació en Rosario y que, gracias a su eficaz administración de los recursos y la gestión de los negocios basada en los valores mutualistas, le permitió crecer y expandirse en todo el país. Desde esta empresa ofrecen un amplio portfolio de coberturas para individuos, productores agropecuarios, comercios, pymes y grandes empresas.",
  //   productos: "",
  // },

  {
    id: 37,
    nombre: "MARE COMEX S.A.S.",
    domicilio: "M.A Carra 1542",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 445873",
    web: "http://www.marecomex.com.ar/",
    rubro: "transporte-logistica",
    descripcion:
      "Mare Logística Internacional es una empresa que se dedica al servicio de logística internacional. Radicada en el Parque Industrial de San Francisco, esta organización cuenta con un depósito fiscal, además de realizar operaciones vinculadas al comercio exterior, tanto en materia de exportación como importación.",
    productos: "",
  },

  {
    id: 38,
    nombre: "INTERNATIONAL CARGO S.A",
    domicilio: "Cerrito 146, Piso 10",
    localidad: "C.A.B.A. , Buenos Aires, Argentina",
    telefono: "+54 011 4384 5555",
    web: "http://www.intlcargo.com.ar/",
    rubro: "transporte-logistica",
    descripcion:
      "Con presencia en los cinco continentes y con una amplia red de agentes, International Cargo es una organización que cuenta con una amplia variedad de planes logísticos para los diferentes tipos de empresas y estructuras, teniendo siempre presente su objetivo: generar ventajas competitivas tanto en los costos como en el tiempo de entrega. Tienen presente que la flexibilización y la adaptación son dos características fundamentales para el crecimiento del cliente, desarrollando relaciones comerciales a largo plazo y agilizando los procesos de gestión de servicio.",
    productos: "",
  },

  {
    id: 39,
    nombre: "PLUS CARGO RAFAELA",
    domicilio: "Bv. Presidente Julio A. Roca 882",
    localidad: "Rafaela",
    telefono: "+54 3492-577036",
    web: "http://www.gruporas.com/pluscargo_agentina",
    rubro: "asesoramiento",
    descripcion:
      "Plus Cargo S.A es una empresa de transporte y logística que se encuentra en ciudades estratégicas de todo el mundo. Mediante su atención personalizada a cargo de profesionales calificados, ofrece servicio aéreo de importación y exportación a todo el mundo, a través de nuestro servicio Priority, Standard o Economy. Además, cuenta con un Servicio directo LCL (consolidados) y FCL (full containers), desde y hacia los principales puertos del mundo.",
    productos: "",
  },

  {
    id: 40,
    nombre: "AXION S.A",
    domicilio: "Entre Ríos 1242",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 430927",
    web: "https://www.axionlift.com",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "AXION se especializa en el diseño, fabricación y comercialización de elevadores hidráulicos e hidrogrúas de alta performance, destinados a todo tipo de organizaciones que necesiten manipular cargas o realizar trabajos en altura. Forma parte del grupo Micrón Fresar, empresa de original nacional con más de 40 años de experiencia en la fabricación de máquinas y conjuntos mecánicos e hidráulicos. Los equipos están certificados por normas internacionales ISO 9001 y ANSI A92.2.",
    productos: "Elevadores, Hidrogrúas",
  },

  {
    id: 41,
    nombre: "CLEAR PACK S.A",
    domicilio: "Santiago Pampiglione 4717",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 423716",
    web: "http://www.grupoinpack.com.ar/",
    rubro: "envases-embotellado",
    descripcion:
      "Grupo In Pack se especializa en la producción de envases descartables para alimentos y sus derivados. Brindando la mejor calidad y variedad de productos, ofrece a sus clientes la posibilidad de mejorar el rendimiento de su negocio a través de una óptima presentación y conservación de los alimentos.",
    productos:
      "Envases descartables como: ensaladeras, bandejas, obleas, platos, potes, tapas, torteras, absorbentes. ",
  },

  {
    id: 42,
    nombre: "HIDRAÚLICA SAN FRANCISCO",
    domicilio: "Lothar Badersbach Nº 4505",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 445924",
    web: "http://www.hidraulicasanfco.com.ar/",
    rubro: "hidraulica",
    descripcion:
      "Hidráulica San Francisco es una empresa que desarrolla sus actividades el marco de dos áreas dentro del sector: servicio y soluciones integrales a los requerimientos e inquietudes de sus clientes. Posee una moderna planta de producción con lineamientos de las normas ISO, departamento técnico y personal con capacidad superior que satisfacen las exigencias del mercado con evolución constante de expansión y de integración hacia una mayor calidad. ",
    productos:
      "Máquinas abrochadoras para mangueras hidráulicas, máquinas cortadoras de mangueras. ",
  },

  {
    id: 43,
    nombre: "INDUSTRIAS J.M S.A",
    domicilio: "Chubut 365",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 433967",
    web: "http://www.industriajm.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Desde 1979 Industria JM ha mantenido dentro del sector una actividad en constante crecimiento, dedicada a la fabricación de bombas de aceite e hidráulicas y partes para tractores. Durante estos años, esta empresa se instaló fuertemente en el mercado, posicionando sus productos como un sinónimo de calidad y confianza. Con sus pilares de calidad, confiabilidad y satisfacción, logró establecer lazos comerciales con Europa, Estados Unidos y varios países de Latioamérica.",
    productos: "Bombas de aceite hidráulicas y partes de tractores.",
  },

  {
    id: 44,
    nombre: "LA CASA DEL TAPICERO S.R.L.",
    domicilio: "Bv. 9 de Julio 1743",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 423823",
    web: "https://casadeltapicero.com.ar/",
    rubro: "construccion-decoracion",
    descripcion:
      "LA CASA DEL TAPICERO SRL es una empresa familiar con más de 45 años de experiencia en venta y distribución de tejidos para decoración.",
    productos: "",
  },

  // {
  //   id: 45,
  //   nombre: "LUIS GUDIÑO",
  //   domicilio: "Belgrano 1305 esq. San Juan",
  //   localidad: "San Francisco (Córdoba)",
  //   telefono: "+54 3564 420608",
  //   web: "http://xn--luisgudiohierros-eub.com/",
  //   rubro: "metalurgia-maquinaria",
  //   descripcion:
  //     "Luis A. Gudiño es una empresa siderometalúrgica dedicada a la comercialización de productos y servicios siderúrgicos. Ofrece una amplia variedad de artículos tales como: perfiles, aceros, caños, tubos, chapas, metales no ferrosos, artículos de ferretería y herrería, insumos para la construcción y el agro. Su objetivo es satisfacer las necesidades de nuestros clientes, brindando un servicio basado en la excelencia, el asesoramiento personalizado y la entrega inmediata",
  //   productos:
  //     "Perfiles, aceros, caños, tubos, chapas y artículos de ferretería y herrería",
  // },

  {
    id: 46,
    nombre: "MAB METALÚRGICA",
    domicilio: "Pueyrredon 1281",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 498302",
    web: "http://www.mabmetalurgica.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Mecanizados y Engranajes FAB S.A. es una empresa con vasta experiencia en el rubro metalúrgico en la ciudad de San Francisco y la región, la cual se especializa en la fabricación y reparación de reductores, rotores y acoples para embolsadoras de forrajes. Cuenta con experiencia para fabricar y reparar cualquier tipo de repuesto para máquinas agrícolas, así como a la construcción y reparación de piezas especiales a pedido de cada uno de sus clientes.",
    productos: "Zafe maicero, reductores y actuadores",
  },

  {
    id: 47,
    nombre: "MACOSER S.A",
    domicilio: "Santiago Pampiglione 4486",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 11 45730888",
    web: "https://www.singerflorencia.com.ar",
    rubro: "electrodomesticos",
    descripcion:
      "MACOSER es una empresa familiar y nacional, fabricante de las cocinas domésticas de marca Florencia y marcas de terceros, y licenciataria de Singer en la Argentina desde 1984. Ubicada en el Parque Industrial de San Francisco donde produce sus artículos para el hogar, Macoser también comercializa y distribuye en todo el territorio nacional más de doce modelos de máquinas de coser y bordados Singer, siendo líder del mercado.",
    productos: "Cocinas, anafes y máquinas de coser. ",
  },

  {
    id: 48,
    nombre: "MANFREY COOP. LTDA.",
    domicilio: "Güemes  107",
    localidad: "Freyre (Córdoba)",
    telefono: "+54 3564 461145",
    web: "https://www.manfrey.com.ar",
    rubro: "alimentos",
    descripcion:
      "Manfrey es una empresa cooperativa láctea argentina de primer grado, con más de setenta y cinco años de permanencia en el mercado, siendo una de las más importantes de Argentina. Se la reconoce como una organización innovadora y fuertemente orientada al consumidor. Desde sus inicios, aplica políticas orientadas hacia la calidad y la seguridad de sus procesos, con el fin de garantizar las óptimas condiciones organolépticas y sanitarias de los productos que elabora.",
    productos: "Leche, quesos, dulce de leche, yogurt. ",
  },
  {
    id: 49,
    nombre: "METALÚRGICA CESCA",
    domicilio: "J.J. Torres 4235",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 445959",
    web: "http://www.metalurgicacesca.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Desde 1961 Metalúrgica Cesca se dedica la fabricación de productos para diferentes empresas, como válvulas, engranajes y demás accesorios para hidráulica de primera calidad. Gracias a la incorporación de nuevas tecnologías en los procesos productivos y a la implementación de maquinaria de última generación, sus productos son comercializados con la calidad que cada cliente necesita.",
    productos: "Válvulas, engranajes, manguitos estriados.",
  },

  {
    id: 50,
    nombre: "METALÚRGICA FEMA",
    domicilio: "J.J. Torres 4380",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 445890",
    web: "https://m.facebook.com/metalurgica.fema.3?locale2=es_LA",
    rubro: "metalurgia-maquinaria",
    descripcion: "Mecanizado C.N.C - Reparaciones en general",
    productos:
      "Máquina enderezadora de llantas, torno para rectificación, torno para discos y campanas.",
  },

  {
    id: 51,
    nombre: "NELSO FERREYRA S.R.L",
    domicilio: "Av. Ing. Huergo 1387",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 426810",
    web: "http://www.nelsoferreyra.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Ubicada en el Parque Industrial de San Francisco y abarcando una superficie de 5000 metros cuadrados, Nelso Ferreyra es una empresa que se dedica a la fabricación de platos y cilindros para tornos con marca registrada DRI-LEX, además de la fabricación de repuestos para camiones y tractores bajo la marca NF. Con más de 60 empleados y una trayectoria de crecimiento firme y sostenido, esta empresa ofrece a sus clientes una diversidad de soluciones en sujeción de piezas para el mecanizado.",
    productos:
      "Platos y cilindros para tornos, repuestos para camiones y tractores.",
  },

  {
    id: 52,
    nombre: "PAUNY S.A",
    domicilio: "Maipú y Reconquista 5940",
    localidad: "Las Varillas (Córdoba)",
    telefono: "+54 3533 423609",
    web: "https://www.pauny.com.ar",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Pauny S.A es una empresa que se dedica a la producción de tractores y máquinas viales. La misma se ubica en Las Varillas y cuenta con más de 500 empleados altamente calificados para brindar soluciones a cada uno de sus clientes, alentando al desarrollo económico del país. Constantemente, trabaja para ser una empresa líder en el rubro metalmecánico. ",
    productos: "Tractores, máquinas viales y transporte.",
  },

  {
    id: 53,
    nombre: "PESSOT S.R.L",
    domicilio: "Santiago Pampiglione 4595",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 445920",
    web: "http://www.pessot.com/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Fundada en 1971 en la ciudad de San Francisco y  con el fin de fabricar equilibradoras de ruedas y alineadoras de dirección, Pessot es una empresa que desarrolla sus actividades en una planta de 5.000 m2 cubiertos y cuenta con personal especializado en la fabricación y comercialización de más de 25 productos para el equipamiento de concesionarios de automóviles, distribuidores de neumáticos, talleres de servicios en general y estaciones de servicio.",
    productos: "Alineadoras de ruedas, Equilibradoras de ruedas, desarmadoras",
  },
  {
    id: 54,
    nombre: "PLUMITA S.R.L",
    domicilio: "Av. 9 de Septiembre 3203.",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 422291",
    web: "https://www.plumita.com/",
    rubro: "electrodomesticos",
    descripcion:
      "La producción de Plumita se fundamenta en un estricto control de calidad en todas las etapas de su proceso, con maquinarias de última tecnología. Cumpliendo Normas de Higiene, Seguridad ambiental y de Seguridad eléctrica es lo que ha permitido lograr productos confiables y competitivos en calidad y precios a nivel Internacional.",
    productos:
      "Cortadoras de cesped, eletrobombas, hidrolavadoras, bordeadoras, motoguadañas",
  },

  {
    id: 55,
    nombre: "RADA IND. Y COM. S.R.L.",
    domicilio: "Rosario Santa Fe 1072",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 425055",
    web: "",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Rada es una empresa sanfrancisqueña dedicada a la producción y comercialización de engranajes y accesorios afines. Su planta industrial está equipada con tecnología de última generación para proveer a fabricantes de tractores, realizando trabajos especializados para cada tipo de cliente.",
    productos:
      "Engranajes para tractores, motos, máquinas viales y maquinaria en general",
  },

  {
    id: 56,
    nombre: "RUBEN COSTANTINI S.A.",
    domicilio: "Ing. Huergo 1320",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 428877",
    web: "https://www.costantini-sa.com",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Rubén Costantini S.A. desempeña una función relevante en el campo de la automatización industrial. Debido a su experiencia en el rubro, están aptos para aportar soluciones eficientes a cualquier necesidad planteada por sus clientes. Poseen alianzas estratégicas con otras empresas, para brindar soluciones con el mejor estándar de calidad. ",
    productos:
      "Celdas de corte automático, de soldadura, encajonado y paletizado automático",
  },

  {
    id: 57,
    nombre: "SERENA ENGRANAJES S.R.L.",
    domicilio: "Santiago Pampiglione 4430",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 445934",
    web: "https://www.serenasrl.com.ar",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Denominándose como una  empresa en constante crecimiento, Serena Engranajes se  preocupa tanto la calidad de fabricación como el cumplimiento en los plazos de entrega para trabajos de extrema urgencia y el respeto a nuestros clientes. La suma de experiencia, conocimiento, compromiso e innovación constante les permiten proveer las mejores soluciones para la fabricación de partes y equipamientos con distribución en todo el país y proyección al exterior para la agroindustria en general.",
    productos: "Engranajes especiales",
  },

  {
    id: 58,
    nombre: "TALLERES GAN MAR S.R.L",
    domicilio: "Calle 5 Nº 2068",
    localidad: "Frontera - Santa Fe - Argentina",
    telefono: "+54 351 5680940",
    web: "http://www.ganmar.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Talleres GanMar es una empresa que nació en 1969 con un único objetivo: cumplir con cada uno de sus clientes. Este propósito no cambió a lo largo de estos años, ya que GanMar cuenta con la capacidad de adaptarse a las demandas del mercado interno como externo, satisfaciendo cada necesidad que se presenta con la eficacia y responsabilidad que los caracteriza. ",
    productos:
      "Polipasto manual y eléctrico, elevador eléctrico monofásico, pluma guinche, cabrestante manual y eléctrico.",
  },

  {
    id: 59,
    nombre: "TORREÓN NEUMÁTICA S.R.L.",
    domicilio: "Geronimo del Barco 1759",
    localidad: "San Francisco (Córdoba)",
    telefono: "0810 444 0073",
    web: "http://torreon.com.ar/",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "Torreón Neumática es una empresa con más de 45 años en la industria nacional que logró posicionarse como una marca global con presencia internacional. Innovación, responsabilidad social y expansión global son tres ejes fundamentales del trabajo que realiza Grupo Torreón, ofreciéndole a cada uno de sus clientes las herramientas ideales.",
    productos: "Taladros, Amoladoras, Pulidoras.",
  },

  {
    id: 60,
    nombre: "VALORASOY S.A.",
    domicilio: "Ruta Nacional 19. Km 200",
    localidad: "El Tío (Córdoba)",
    telefono: "+54 3564 41 6102",
    web: "http://www.valorasoy.com/",
    rubro: "alimentos",
    descripcion:
      "ValorA es una empresa familiar que agrega valor a la producción primaria. Desarrolla y provee proteínas vegetales que aportan calidad a los productos de la industria alimenticia. Desde el año 2011 y con una planta ubicada en El Tío, esta PyME exporta el 100% de toda su producción, logrando establecer lazos comerciales con diferentes regiones del mundo e introduciendo mayor valor agregado a sus productos.",
    productos:
      "Proteínas vegetales de soja, expeller de soja y aceite desgomado de soja",
  },

  {
    id: 61,
    nombre: "WEG EQUIP. ELECT. S.A",
    domicilio: "Sgo. Pampiglione 4849",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 421484",
    web: "https://www.weg.net",
    rubro: "electricidad-electronica",
    descripcion:
      "De capital brasilero y ubicada estratégicamente en el Parque Industrial de San Francisco, WEG es una empresa que se dedicada a la fabricación de motores, equipos eléctricos, equipos de energía y transporte. Con el objetivo de mantener una estructura simple de negocios, WEG se abre al mundo ofreciendo sus productos y buscando nuevos horizontes, cuidando el capital humano que participa en cada uno de los procesos de producción.",
    productos: "Tableros , automatización industrial, motores eléctricos",
  },

  {
    id: 62,
    nombre: "ZF SACHS S.A",
    domicilio: "Avenida de la Universidad 51",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 438900",
    web: "https://www.zf.com",
    rubro: "metalurgia-maquinaria",
    descripcion:
      "ZF es una compañía de tecnología global y suministra sistemas para turismos, vehículos comerciales y tecnología industrial, lo que permite la próxima generación de movilidad. La compañía ofrece soluciones integradas para fabricantes de vehículos establecidos, proveedores de movilidad y empresas nuevas en los campos del transporte y la movilidad. Esta empresa mejora continuamente sus sistemas en las áreas de conectividad digital y automatización.",
    productos: "Amortiguadores, repuestos y embragues",
  },
  {
    id: 63,
    nombre: "HIELO Y AGUA ITALIA",
    domicilio: "Juan de Garay 2567",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564 677217",
    web: "",
    rubro: "alimentos",
    descripcion:
        "Hielo y agua Italia es una empresa sanfrancisqueña en constante crecimiento que produce y comercializa agua alcalina ionizada con mínimo contenido de sodio, contribuyendo a la salud de cada consumidor no solo de Argentina sino también del exterior.",
    productos: "",
  },
  {
    id: 64,
    nombre: "COMPAÑIA ARGENTINA DE ALFALFAS Y FORRAJES S.A",
    domicilio: "Av. 9 de Septiembre 1093",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 385 688-1159",
    web: "https://www.arg-gccgroup.com",
    rubro: "alimentos",
    descripcion:
        "CADAF es una empresa alimenticia que busca potenciar la producción de alfalfa  y comercialización de forrajes, que tienen como destino los mercados árabes y de medio oriente.",
    productos: "",
  },
  {
    id: 65,
    nombre: "COMPAÑIA ARGENTINA DE LOGÍSTICA INTERMODAL Y FERROVIARIA S.A",
    domicilio: "Avenida 9 de Septiembre 1100",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 341 6167709",
    web: "https://www.califsa.com",
    rubro: "transporte-logistica",
    descripcion:
        "CALIF S.A Nodo Logístico San Francisco es una empresa que se desarrolló  bajo el concepto de puerto seco, es decir, una terminal intermodal conectada directamente a un puerto marítimo por ferrocarril donde las empresas pueden dejar y recoger sus unidades estandarizadas como si fueran directamente al puerto marítimo.",
    productos: "",
  },
  {
    id: 66,
    nombre: "HELIT S.A",
    domicilio: "Mitre 5186",
    localidad: "San Martin (Bs As)",
    telefono: "+54 11 51783737",
    web: "https://www.aromitalia.com.ar",
    rubro: "alimentos",
    descripcion:
        "Helit S.A es una empresa alimenticia que produce ingredientes para la elaboración de helados artesanales de calidad.",
    productos: "",
  },
  {
    id: 67,
    nombre: "DISEGNO SOFT S.R.L",
    domicilio: "Belisario Roldán 236",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-421996",
    web: "https://www.disegnosoft.com.ar",
    rubro: "informacion-software",
    descripcion:
        "Disegno Soft es una empresa de servicios que especializada en proveer y comercialización de Soluciones Tecnológicas.",
    productos: "",
  },
  {
    id: 68,
    nombre: "ENRIQUE CHAO S.R.L",
    domicilio: "Liniers 629",
    localidad: "San Francisco (Córdoba)",
    telefono: "+54 3564-420432",
    web: "https://www.enriquechao.com.ar",
    rubro: "informacion-software",
    descripcion:
        "Desde 1970, estamos invirtiendo, expandiéndonos y liderando los mercados en los que intervenimos. Contamos con un equipo de profesionales en cada área, la capacitación constante, el desarrollo y equipamiento de última tecnología son la combinación exacta para que nuestros clientes depositen su confianza y satisfacción en nuestros trabajos.",
    productos: "",
  },
  {
    id: 69,
    nombre: "LOG4",
    domicilio: "Av Rivadavia 10702 - 3ro C",
    localidad: "San Francisco (Córdoba)",
    telefono: "(+54) 11 2404-2934\n" +
        "(+54) 11 4079-2361",
    web: "https://log4.com.ar/ ",
    rubro: "transporte-logistica",
    descripcion:
        "Somos una empresa joven liderada por un equipo de profesionales con una gran trayectoria en logística nacional e internacional. Contamos con bases operativas en Buenos Aires, Bahía Blanca, Comodoro Rivadavia, Mendoza y San Juan. Nuestros clientes son empresas de diversas industrias (Pharma, Energías Renovables, Minería, Ind Química) desde pymes hasta multinacionales. Apoyamos el crecimiento y somos conscientes del esfuerzo que acarrea optimizar los recursos de nuestros clientes.",
    productos: "",
  },
  {
    id: 70,
    nombre: "SERCOME",
    domicilio: "Av Rivadavia 10702 - 3ro C",
    localidad: "Centenario 490, Devoto (Córdoba)",
    telefono: "(+54) 351 5190531",
    web: "https://sercomeargentina.com.ar/",
    rubro: "informacion-software",
    descripcion:
        "Sercome Argentina se caracteriza por brindar un servicio profesional y eficiente de calibración y certificación de instrumentos de medición, utilizando patrones de calibración trazables con INTI o bien con patrones europeos reconocidos.\n" +
        "\n" +
        "Además, Sercome Argentina, a través de su consultora ETP formada por un equipo de profesionales de distintas especialidades, brinda servicios de Implementación y Mantenimiento en Sistemas de Gestión (ISO 9001-14001-45001, BRC), Auditoria Internas, a Proveedores, de 2da parte, y Capacitaciones y Entrenamientos (Presencial y a Distancia) en distintos temas de interés como ser Metrología, Auditoría Interna, Normas ISO, Ciclo de vida del producto, Gestión alimentaria, etc.\n" +
        "\n" +
        "Sercome Argentina se caracteriza por brindar siempre una solución a medida de lo que sus clientes necesitan.",
    productos: "",
  },
];

export default empresas;
