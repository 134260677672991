import { Col, Row, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import emailjs from "emailjs-com";
import { useState } from "react";

const Contacto = () => {
  const [sendEmail, setSendMail] = useState(false);
  const [error, setError] = useState(false);
  let formulario = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    emailjs
      .sendForm(
        "service_ujq5jkp",
        "template_cmrtchd",
        formulario.current,
        "user_xHH97OYcof5DFzCRPl6Fb"
      )
      .then(
        (result) => {
          setSendMail(true);
        },
        (error) => {
          setError(true);
        }
      );
    formulario.current.reset();
  };

  return (
    <>
      <div className="bg-top-contacto">
        <Container>
          <Row>
            <Col>
              <div className="title-view">
                <h1>Contáctanos</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="container-contact">
              <div className="form-container">
                <p>
                  Lo invitamos a dejarnos su consulta y los motivos de la misma.
                  A la brevedad nos comunicaremos con usted.
                </p>
                <form ref={formulario} onSubmit={handleSubmit(onSubmit)}>
                  <div className="contact-name">
                    <p>Nombre completo</p>
                    <input
                      {...register("name", {
                        required: true,
                        minLength: 3,
                        maxLength: 30,
                      })}
                      type="text"
                    />
                    {errors.name && <span>Ingresa un nombre válido.</span>}
                  </div>

                  <div className="container-duo">
                    <div className="contact-asunto-email">
                      <p>Email</p>
                      <input
                        type="text"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email && <span>Ingresa un mail válido.</span>}
                    </div>
                    <div className="contact-asunto-email">
                      <p>Motivo</p>
                      <input
                        type="text"
                        {...register("motivo", {
                          required: true,
                          minLength: 3,
                          maxLength: 30,
                        })}
                      />
                      {errors.motivo && <span>Ingresa un asunto válido.</span>}
                    </div>
                  </div>

                  <div className="contact-mensaje">
                    <p>Mensaje</p>
                    <textarea
                      {...register("mensaje", {
                        required: true,
                        minLength: 10,
                        maxLength: 200,
                      })}
                    ></textarea>
                    {errors.mensaje && (
                      <span>
                        El mensaje debe tener un mínimo de 10 caracteres.
                      </span>
                    )}
                  </div>
                  <button className="button-submit" type="submit">
                    ENVIAR MENSAJE
                  </button>
                </form>
                {sendEmail ? (
                  <p className="successMessage"> Mensaje enviado con exito.</p>
                ) : null}
                {error ? (
                  <p className="errorMessage">Error al enviar el mensaje.</p>
                ) : null}
              </div>

              <div className="info-container">
           <div>
                <div className="date">
                  <div className="date-icon ">
                    <i className="fas fa-map-marker-alt ubic"></i>
                  </div>
                  <div className="date-text">
                    <p className="date-title">Dirección</p>
                    <p>
                      Santiago Pampiglione 4891, San Francisco, Córdoba,
                      Argentina
                    </p>
                  </div>
                </div>

                <div className="date">
                  <div className="date-icon">
                    <i className="fas fa-phone-alt"></i>
                  </div>
                  <div className="date-text">
                    <p className="date-title">Teléfono</p>
                    <p>+549 3564-632808</p>
                  </div>
                </div>

                <div className="date">
                  <div className="date-icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="date-text">
                    <p className="date-title">Email</p>
                    <p>secretaria@ccesf.com.ar</p>
                  </div>
                </div>

                <div className="contact-redes">
                  <a
                    href="https://www.facebook.com/CCESanFco"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://twitter.com/camara_exterior"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/c%C3%A1mara-de-comercio-exterior-de-san-francisco-y-la-regi%C3%B3n/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/camaradecomercioexterior/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.1099892854954!2d-62.12895168515737!3d-31.438638804473936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cb288baf453419%3A0xee40c6b1bbeacc00!2sSantiago%20Pampiglione%204891%2C%20San%20Francisco%2C%20C%C3%B3rdoba!5e0!3m2!1ses-419!2sar!4v1630510470713!5m2!1ses-419!2sar"
          width="100%"
          height="300"
          title="mapa"
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};

export default Contacto;
