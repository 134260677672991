import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import entidadesAsociadas from "../../utils/entidadesData";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Autoplay, Navigation]);

const SliderEntidades = () => {
  return (
    <Swiper
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        650: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        800: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      navigation={true}
      className="slider-entidades"
    >
      {entidadesAsociadas.map((entidad) => (
        <SwiperSlide key={entidad.url}>
          <div className="entidad">
            <a href={entidad.url} target="_blank" rel="noreferrer">
              <img
                className="entidad-img"
                src={entidad.img}
                alt="asociados"
              ></img>
            </a>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderEntidades;
